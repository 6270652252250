.option-groups {
  &__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 670px;
    width: 100%;
    margin-top: 5vh;
    padding: 10px;
    @include for-phone-only {
      margin-top: 30px;
      padding: 0;
    }
  }
  &__link {
    margin: 29px 20px;
    cursor: pointer;
    display: flex;
    color: $accent-color;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-decoration: none;
    align-items: center;
    @include filled-icon;

    .icon {
      margin-right: 10px;
    }

    &_revert {
      flex-direction: row-reverse;

      .icon {
        margin-right: 0;
        margin-left: 18px;
      }
    }
  }
  &__list {
    margin-bottom: 30px;
  }
  &__save-btn {
    margin: 50px auto 50px auto;
  }
  &__hint {
    text-align: center;
    @include p2;
    color: $font-color-l;
    margin-top: -50px;
    margin-bottom: 35px;
    @include for-phone-only {
      padding: 0 50px;
    }
  }
  &__content {
    padding: 0 20px;
  }
}
.option-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 18px 18px 30px 18px;
  border-radius: 20px;
  margin-bottom: 3px;
  background: #FFFFFF;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  @include p2;
  cursor: pointer;
  &__name {
    @include h2;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    p {
      flex: 1 1;
    }
  }
  &__pen-icon {
    margin-left: 15px;

    svg > circle {
      stroke: $border-color;
    }
  }
  &__technical-name {
    margin-top: 10px;
    color: $font-color-l;
  }
  &__options-list {
    scroll-padding-top: 100px;
    display: flex;
    flex-direction: column;
    width: 100%;
    @include transition(all);


    &_hide {
      animation: hideOptionsList 0.3s linear forwards;
    }
    &_show {
      animation: showOptionsList 0.3s linear forwards;
    }
  }
  &__option{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 20px;
    background: #FFFFFF;
    box-shadow: 0 4px 8px rgba(22, 11, 36, 0.15);
    margin-bottom: 3px;
    @include p1;
  }
  &__options-list {
    margin-left: 10px;
    list-style: inside;
    &__item {
      margin-top: 10px;
      color: $font-color-l;
    }
    &__controls {
      display: flex;
      justify-content: flex-end;
    }
  }
  &__settings {
    margin-top: 20px;
    .info-icon {
      margin-left: 15px;
      margin-top: 5px;
    }
    &-container {
      display: flex;
      @include for-phone-only {
        justify-content: space-between;
      }
      @include for-phone-only {
        padding: 0 20px;
      }
    }
    &-input-container {
      display: flex;
    }
    &-input {
      margin-top: 25px;
      &:last-child{
        margin-left: 10px;
      }
    }
    &-block {
      @include h2;
      margin-top: 25px;
      @include for-phone-only {
        padding: 0 20px;
      }
      &-title {
        display: flex;
        @include for-phone-only {
          justify-content: space-between;
        }
      }
    }
    &-error{
      text-align: center;
    }
  }
}

@keyframes hideOptionsList {
  0% {
    opacity: 1;
    overflow: unset;
  }
  100% {
    height: 0;
    overflow: hidden;
    padding: 0;
    opacity: 0;
  }
}

@keyframes showOptionsList {
  0% {
    opacity: 0;
    overflow: hidden;
  }
  100% {
    opacity: 1;
    overflow: unset;
    height: fit-content;
  }
}