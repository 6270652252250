.subscription-result{
  &__button {
    position: absolute;
    align-self: center;
    bottom: 10%;
  }
  &__emoji {
    margin-top: 50px;
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.02em;
    text-align: center;
  }
  &__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 25%;
    background-color: $bcg-color;
    flex-grow: 1;
    padding: 0 10px;
    margin-top: 25px;
    @include for-phone-only {
      margin-top: 20px;
    }
  }
  &__description {
    margin: 10px auto;
    width: 60%;
    text-align: center;
    @include h3;
  }
  &__title {
    color: $link-color;
    @include h1;
    &_error {
      color: #FF0000;
    }
  }
}