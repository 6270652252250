.icon {
  @include fxc;
  width: max-content;
  height: max-content;

  cursor: pointer;

  svg > circle {
    @include transition(all);
  }

  &_with-hover {
    &:hover {
      svg > circle {
        stroke: $link-color;
        fill-opacity: 1;
        stroke-opacity: 1;
      }
      svg > path {
        fill: $link-color;
      }
    }
  }
}
