@mixin gradient-hover {
  &:before {
    background: linear-gradient(
                    270deg,
                    rgba(139, 133, 146, 0) 0%,
                    rgba(139, 133, 146, 0.05) 50%,
                    rgba(139, 133, 146, 0) 100%
    );
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    @include transition(opacity);
  }
}

.google-autocomplete {
  width: 100%;
  margin-bottom: 25px;
  position: relative;
}

.google-place-auto-complete__dropdown {
  z-index: 10;
  position: absolute;
  top: 43px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(22, 11, 36, 0.15);
  border-radius: 0 0 10px 10px;
  overflow: auto;
  @include transition(max-height);
  max-height: calc(50px * 4);

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: $bcg-color;
  }

  &::-webkit-scrollbar-thumb {
    background: #afafaf;
    border: 2px solid rgba(0, 0, 0, 0.08);
    border-radius: 15px;
  }
}

.google-place-auto-complete__dropdown-item {
  @include p1;
  @include transition(all);
  @include gradient-hover;

  position: relative;
  display: flex;
  align-items: center;
  color: $font-color-d;
  min-height: 50px;
  padding-left: 8px;
  cursor: pointer;

  &:hover {
    &:before {
      opacity: 1;
    }
  }
}