.restaurants-list {
  flex-grow: 1;
  height: 100%;
  position: relative;
  background: unset;

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    padding: 0 20px 20px;
    z-index: 1;
  }

  &__list {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
  }

  &__item {
    border-bottom: 1px solid $border-color;

    &:before {
      background: linear-gradient(
        270deg,
        rgba(139, 133, 146, 0) 0%,
        rgba(139, 133, 146, 0.05) 50%,
        rgba(139, 133, 146, 0) 100%
      );
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      @include transition(opacity);
    }

    &:hover {
      cursor: pointer;
      &:before {
        opacity: 1;
      }
    }

    &:first-child {
      @include for-phone-only {
        border-top: 1px solid $border-color;
        margin-bottom: 0;
      }
    }
  }

  &__footer {
  }
}

.restaurants-list-item {
  display: flex;
  padding: 10px 0;
  position: relative;
  &__menu {
    position: absolute;
    top: 20px;
    right: 0;
  }

  &__name {
    word-break: break-word;
    margin-right: 40px;
    @include h2;
  }

  &__subscription-info {
    margin-top: 5px;
    @include p2;
    &_active {
      color: $link-color;
    }
    &_trial {
      color:$font-color-l;
    }
    &_expired {
      color: #FF0000;
    }
  }
}
