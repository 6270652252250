.iiko-payments-sync {
  &__container {
    margin-top: 30px;
  }
  &__title {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }
  &__info {
    margin-left: 15px;
  }

}