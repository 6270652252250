
.checkout {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;

  &__content {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    flex-grow: 1;
    padding-bottom: 80px;
    @include for-phone-only {
      padding-right: 20px;
      padding-left: 20px;
    }
  }

  &__message-text {
    padding: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    @include p2;
    margin-bottom: 30px;
  }

  &__component-with-icon {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    svg {
      width: 20px;
    }

  }

  &__component-with-icon > .icon {
    margin-bottom: 25px;
    margin-right: 18px;
  }

  &__form {
    width: 100%;
  }

  &__order {
    margin-bottom: 20px;
  }

  &__total {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @include h3;
    margin-bottom: 90px;
  }

  &__button {
    margin: auto;
  }
}

.checkout-order {
  @include fxc;
  flex-direction: column;

  &__title {
    @include h3;
    margin-bottom: 30px;
  }

  &__product-list {
    width: 100%;
  }

  &__product {
    width: 100%;
  }
}

.checkout-order-product {
  padding: 20px 0;
  border-top: 1px solid #DDDDDD;
  display: flex;
  align-items: center;
  flex-direction: column;

  &:last-child {
    border-bottom: 1px solid #DDDDDD;
  }

  &__row {
    width: 100%;
    display: grid;
    grid-template-columns: 10% 1fr max-content;
    grid-gap: 0 15px;
    align-items: center;
  }

  &__name, &__quantity, &__price  {
    @include p1
  }

  &__quantity-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

  }

  &__options {
    display: flex;
    flex-direction: column;
  }

  &__option {
    @include p2;
    color: $font-color-l;
  }

}
