.tabs {
  width: 100%;
  display: flex;
  flex-direction: column;

  @include for-phone-only {
    margin-top: 0;
  }

  &__header {
    display: flex;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;
    padding: 4px;
    margin: 0 auto;
    color: #5c5566;
    background: $bcg-color-d;
    border-radius: 24px;
    opacity: 0.7;
    width: 100%;
  }

  &__header-tab {
    cursor: pointer;
    flex: 1;
    padding: 10px 0;
    text-align: center;
    @include transition(all);

    &_active {
      color: $link-color;
      background: $bcg-color;
      box-shadow: 0px 2px 4px rgba(22, 11, 36, 0.1);
      border-radius: 20px;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    margin: 30px auto 30px;
    width: 100%;
  }
}
