.menu {
  z-index: 10;
  &__icon {

  }

  &__container {
    position: fixed;
    top: 0;
    right: -100%;
    opacity: 0;
    bottom: 0;
    width: 600px;
    background: #FFFFFF;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    border-radius: 16px 0px 0px 16px;
    transition: all 1s ease;
    @include for-phone-only {
      transition-duration: 0.5s;
      width: 100%;
    }

    &_open {
      right: 0;
      opacity: 1;
    }
  }

  &__close-icon {
    position: absolute;
    top: 50px;
    right: 80px;
    @include transition(all);
    @include for-phone-only {
      top: 20px;
      right: 20px;
    }
    &:hover {
      transform: rotate(180deg);
    }
  }

  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 100px 100px 50px 100px;

    @include for-phone-only {
      padding: 100px 20px 50px 20px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    width: 100%;
  }

  &__item {
    position: relative;
    text-decoration: none;
    border-top: 1px solid #E8E7EA;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: 0.02em;
    padding: 20px 0;
    color: $font-color-d;
    &:before {
      background: linear-gradient(270deg, rgba(139, 133, 146, 0) 0%, rgba(139, 133, 146, 0.05) 50%, rgba(139, 133, 146, 0) 100%);
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      top: 0; left: 0;
      opacity: 0;
      width: 100%;
      @include transition(opacity)
    }

    &:last-child {
      border-bottom: 1px solid #E8E7EA;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
      background: #FFFFFF;
      border-radius: 50px;
      outline: none;
      height: 56px;
      width: 160px;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      color: #8B8592;
      border: none;
      margin-bottom: 30px;
      &:hover {
        border: 1px solid #E8E7EA;
      }
    }

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $font-color-d;

      a {
        text-decoration: none;
        color: $link-color;
      }
    }

  }

}
