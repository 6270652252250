.language-block {
  width: 250px;
  margin: 20px auto;

  &__options-block {
    margin: 0 0 40px 0;
  }
  &__options-groups {
    margin: 0 auto 80px auto;
  }

  &__option {
    color: inherit !important;
    justify-content: space-between;
    @include p2;
    @include transition(all);
    @include gradient-hover;
    &:first-child {
      border-top: 1px solid #DDDDDD;
    }

    position: relative;
    display: flex;
    align-items: center;
    min-height: 50px;
    padding-left: 8px;
    cursor: pointer;

    &_active,
    &:hover {
      &:before {
        opacity: 1;
      }
    }
  }

    &_header {
      background: $bcg-color;
      border-radius: 20px;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
      width: 68px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      cursor: pointer;
      min-height: 32px;
      margin: 0 0 0 10px;
    }

  &__select {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    cursor: pointer;
    min-height: 48px;
    border-radius: 16px;
    background: $bcg-color;
    border: 1px solid #DDDDDD;
    &_open {
      border-radius: 16px 16px 0 0;
      border-bottom: 0;
    }
  }

  &__input {
    @include p2;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    &-text {
      margin-right: 10px;
    }
  }


  &__dropdown-content {
    width: 90%;
    margin: 0 auto;
  }


  &__dropdown {
    color: #8B8592 !important;
    z-index: 20;
    position: absolute;
    max-height: 0;
    border: 1px solid #DDDDDD;
    top: 68px;
    width: 250px;
    background: #ffffff;
    border-radius: 0 0 16px 16px;
    overflow: auto;
    @include transition(max-height);

    &_open {
      border-top: 0;
      max-height: calc(50px * 4);
    }

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background: $bcg-color;
    }

    &::-webkit-scrollbar-thumb {
      background: #afafaf;
      border: 2px solid rgba(0, 0, 0, 0.08);
      border-radius: 15px;
    }
  }

  &__add-button {
    color: inherit !important;
    @include gradient-hover;
    @include p2;
    @include transition(all);
    position: relative;
    display: flex;
    align-items: center;
    min-height: 50px;
    padding-left: 8px;
    cursor: default;

    &:before {
      background: linear-gradient(
                      270deg,
                      rgba(139, 133, 146, 0) 0%,
                      rgba(139, 133, 146, 0.05) 50%,
                      rgba(139, 133, 146, 0) 100%
      );
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      @include transition(opacity);
    }
    .icon {
      svg circle {
        stroke: transparent;
      }
    }

    &_active {
      color: $font-color-d;
      cursor: pointer;

      &:hover {
        &:before {
          opacity: 1;
        }
      }
    }

    &_text {
      margin-left: 15px;
    }
  }
}
