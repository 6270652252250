.feedback {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;

  &__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 500px;
    width: 100%;
    margin-top: 10vh;
    background-color: $bcg-color;
    @include for-phone-only {
      margin-top: 10px;
      padding: 25px 20px 0 20px;
    }
  }

  &__text {
    text-align: center;
    color: $accent-color;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin: auto auto 50px auto;
  }

  &__save-btn {
    margin: 0 auto 50px auto;
    @include for-phone-only {
      margin-bottom: 110px;
    }
  }

}
