.restaurants-list-empty {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background: $bcg-color;
  flex-grow: 1;
  overflow: hidden;
  position: relative;

  @mixin marginTop {
    margin-top: 36px;
    @include for-phone-only {
      margin-top: 20px;
    }
  }

  &__menu {
    position: absolute;
    top: 50px;
    right: 80px;
    @include for-phone-only {
      top: 20px;
      right: 20px;
    }
  }

  &__content {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 1;
    height: 100%;
    max-width: 460px;
    flex-grow: 1;
    position: relative;
    @include for-phone-only {
      margin-top: 130px;
      justify-content: flex-start;
    }


    &-bg {
      position: absolute;
      height: 140%;
      width: 100%;
      transform: translateZ(0);
      @include for-phone-only {
        height: 100%;
        width: 140%;
        bottom: 10%;
      }
      animation: rotating 10s linear infinite;
    }
  }

  &__title {
    margin-top: 130px;
    margin-bottom: 30px;
    position: relative;
    text-align: center;
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    @include for-phone-only {
      margin-top: 74px;
    }
  }

  &__text {
    @include marginTop;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: 0.02em;
    text-align: center;
    margin-bottom: 30px;
    color: $font-color-l;
    @include for-phone-only {
      max-width: 350px;
      font-size: 20px;
      line-height: 24px;
    }
  }

  &__button {
    margin-bottom: 50px;
    @include for-phone-only {
      margin-bottom: 10px;
      position: absolute;
      bottom: 30px;
    }
  }
}

.restaurants-list-body {
  @include fxc;
  flex-direction: column;
  position: absolute;
  max-width: 320px;

  &__title {
    font-size: 24px;
    font-weight: 500;
    margin: 20px 0;
  }

  &__text {
    text-align: center;
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
