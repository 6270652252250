.settings {
  &__block {
    padding: 30px 20px;
    background: $bcg-color;
    border-radius: 20px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    margin-bottom: 3px;
    display: flex;
    flex-direction: column;

    &-top {
      display: flex;
    }
    &-top.payment {
      margin-bottom: 10px;
    }

    &-info{
      transform: translateY(30%);
      height: fit-content;
    }

    &-toggle {
      flex: auto;
    }

    &-order {
      margin-top: 20px;
    }

  }

  &__payment-type {
    margin-top: 10px;

    &-provider {

      &-name {
        @include h3;
        margin-top: 20px;
        margin-bottom: 15px;
      }

      margin-bottom: 30px;

      a {
        color: $disable-color;
      }
    }
    &-secondary-merchant {
      margin-bottom: 10px;
    }
  }

  &__checkbox {
    display: flex;
    margin-bottom: 10px;

    &-text {
      margin-left: 15px;
    }
  }

  &__button {
    margin: 30px auto;
  }

  &__error {
    @include p2;
    text-align: center;
    margin: auto;
    width: 80%;
    color: #FF0000;
  }

  &__sync-tip-text {
    @include p2;
    text-align: center;
    margin: 10px auto;
    width: 80%;
  }

  &__select-tweak {
    .select__value {
      padding-left: 0;
    }
  }
}
