.ring {
  cursor: pointer;
  z-index: 10;

  @include no-select;

  .icon,
  .icon:hover {
    svg {
      height: 42px;
      width: 42px;

      circle {
        fill: none;
        stroke: none;
      }
    }

    circle {
      transition: none;
      fill: $link-color;
    }

    path {
      fill: $bcg-color;
    }
  }

  &__ring-icon {
    transform-origin: center 10%;
    width: 20px;
    &_waiting {
      animation: ring 6s infinite ease;
    }
  }

  &__button {
    display: flex;
    height: 42px;
    align-items: center;
    justify-content: space-evenly;
    background-color: $link-color;
    border-radius: 50px;
    width: 42px;
    @include transition(all);
    &:hover {
      background-color: $hover-color;
    }
  }

  &_active &__button {
    width: 100px;
  }

  &__list {
    position: absolute;
    display: block;
    bottom: 40px;
    width: 170px;

    transition: height, opacity 1s ease-in;

    &_show {
      display: block;
      opacity: 1;
    }

    &_hide {
      animation: hideList 1 1s linear forwards;
    }

    &-item {
      cursor: pointer;
      margin: 10px 0;
      @include fxc;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      background-color: $link-color;
      min-height: 40px;
      color: $bcg-color;
      border: 1px solid $link-color;
      border-radius: 20px;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
      @include transition(background-color);
      &:hover {
        background-color: $hover-color;
      }

      @for $i from 1 through 5 {
        &:nth-child(#{$i}) {
          --animate-duration: #{1 / $i}s;
        }
      }
    }
  }

  &__timer {
    @include fxc;
    height: 100%;
    width: 32px;
    text-align: center;
    color: $bcg-color;
  }
}

@keyframes hideList {
  50% {
    opacity: 0;
  }
  100% {
    height: 0;
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }
  5% {
    transform: rotate(20deg);
  }

  10% {
    transform: rotate(-20deg);
  }
  15% {
    transform: rotate(5deg);
  }
  20% {
    transform: rotate(0);
  }
  37.5% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(0);
  }
  75% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
