.product-option {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 20px 25px 20px;
  border-radius: 20px;
  background: #FFFFFF;
  box-shadow: 0 4px 8px rgba(22, 11, 36, 0.15);
  margin-bottom: 3px;

  &:first-child {
    border-top: 1px solid #E8E7EA;
  }


  p {
    color: $accent-color;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    transform: translateY(35%);
  }

  &__name {
    margin-bottom: 25px;
  }

  &__price {
    flex: 4 1;
  }

  &__bottom-line {
    display: flex;
  }

  &__remove {
    display: flex;
    input {
      transform: translateY(20%);
    }
  }

  &__hide {
    margin-right: 15px;
  }
  &__delete {
    border: solid 1px #dddddd;
    border-radius: 20px;
  }
}

.required-product-option {
  width: 100%;
  display: flex;
  padding: 10px 20px 25px 25px;
  margin-bottom: 3px;
  cursor: pointer;

  p {
    color: $accent-color;
    @include p2;
  }

  &__name {
    margin-bottom: 10px;
  }

  &__radio {

  }

}