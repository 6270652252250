.restaurant-modify {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;

  &__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 500px;
    width: 100%;
    background-color: $bcg-color;
    @include for-phone-only {
      margin-top: 10px;
      padding: 0 20px;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 30px;
    text-align: center;
    @include for-phone-only {
      font-size: 28px;
      line-height: 40px;
    }
  }

  &__save-btn {
    margin: auto auto 50px auto;
  }

  &__img-btn {
    input[type=file] {
      visibility: hidden;
      position: absolute
    }

    @include fxc;
    flex-direction: column;
    position: absolute;
    font-size: 16px;
    line-height: 19px;
    color: $font-color-l;
    top: 80px;
    @include for-phone-only {
      top: 40px;
    }
  }


  &__img {
    position: absolute;
    bottom: -20px;
  }

}
