.qr-code {
  display: flex;
  height: 100%;
  width: 100%;
  flex-grow: 1;
  flex-direction: column;

  &__title {
    @include h2;
  }

  &__content {
    margin: 0 auto;
    max-width: 800px;
    padding: 0 20px;
    padding-top: 50px;
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-direction: column;
    @include for-phone-only {
      padding: 25px 20px;
    }
  }

  &__thx-message {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1;
  }

  &__text {
    text-align: center;
    color: $font-color-l;
    letter-spacing: 0.02em;
    margin-bottom: 50px;

    @include for-phone-only {
      font-size: 20px;
      line-height: 24px;
    }

  }

  &__table {
    &-input {
      margin: auto;
      font-size: 20px;
      width: 100px;

      .input__component {
        font-size: 20px;
        text-align: center;
      }
    }

    &-text {
      text-align: center;
      color: $accent-color;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin: auto auto 50px auto;
    }
  }

  &__btn {
    width: 335px;
    margin: 0 auto 80px auto;
    @include for-phone-only {
      margin-bottom: 50px;
    }
  }
}
