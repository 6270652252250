$spacing: 5px;

.m-0 {
  margin: 0;
}

/**
 MARGIN
 */
.m-1 {
  margin: 1 * $spacing;
}

.m-2 {
  margin: 2 * $spacing;
}

.m-3 {
  margin: 3 * $spacing;
}

/**
 MARGIN-LEFT
 */
.ml-1 {
  margin-left: 1 * $spacing;
}

.ml-2 {
  margin-left: 2 * $spacing;
}

.ml-3 {
  margin-left: 3 * $spacing;
}

/**
 MARGIN-RIGHT
 */
.mr-1 {
  margin-right: 1 * $spacing;
}

.mr-2 {
  margin-right: 2 * $spacing;
}

.mr-3 {
  margin-right: 3 * $spacing;
}

/**
 MARGIN-left and right
 */
.mx-1 {
  margin: 0 1 * $spacing;
}

.mx-2 {
  margin: 0 2 * $spacing;
}

.mx-3 {
  margin: 0 3 * $spacing;
}

.mx-4 {
  margin: 0 4 * $spacing;
}

/**
 MARGIN-top
 */
.mt-1 {
  margin-top: 1 * $spacing;
}

.mt-2 {
  margin-top: 2 * $spacing;
}

.mt-3 {
  margin-top: 3 * $spacing;
}

.mt-4 {
  margin-top: 4 * $spacing;
}

.mt-5 {
  margin-top: 5 * $spacing;
}

.mt-6 {
  margin-top: 6 * $spacing;
}

/**
 MARGIN-bottom
 */
.mb-1 {
  margin-bottom: 1 * $spacing;
}

.mb-2 {
  margin-bottom: 2 * $spacing;
}

.mb-3 {
  margin-bottom: 3 * $spacing;
}

.mb-4 {
  margin-bottom: 4 * $spacing;
}

.mb-5 {
  margin-bottom: 5 * $spacing;
}

.mb-6 {
  margin-bottom: 6 * $spacing;
}

.mb-7 {
  margin-bottom: 7 * $spacing;
}
.mb-8 {
  margin-bottom: 8 * $spacing;
}
