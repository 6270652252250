.iiko-payments-block {
  padding: 30px 20px;
  background: $bcg-color;
  border-radius: 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  margin-bottom: 3px;
  display: flex;
  flex-direction: column;
  &__title {
    @include h2;
  }
  &__content {
    margin-top: 20px;
  }
}