.user-controls {
  touch-action: none;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 5px;
  right: 5px;
  bottom: 40px;
  max-width: $container-width;
  margin: 0 auto;
  z-index: 10;
  transition: 1s bottom;

  @include for-phone-only {
    bottom: 30px;
    left: 20px;
    right: 20px;
  }

  &__ring {
    border-radius: 50%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  }

  &__burger {
    @include filled-icon;
  }
}
