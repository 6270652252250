.cart-options-header {
  min-height: 180px;
  width: 100%;
  z-index: 1;
  margin-top: -20px;
  position: relative;
  @include for-phone-only {
    min-height: 120px;
  }

  &__product {

    padding: 0 20px;

    p {
      margin-top: 15px;
    }

    &-name {
      @include p1();
      color: $font-color-d;
    }

    &-info {
      white-space: pre-line;
      @include p2();
      color: $font-color-l;
    }
    &_no-image {
      margin-top: 110px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

      &_without-image {
        align-items: center;
      }

  }

  &__left-icon {
    position: absolute;
    left: 10px;
    top: 20px;
    @include for-phone-only {
      top: 20px;
      left: 20px;
    }
  }

  &__right-icon {
    position: absolute;
    top: 20px;
    right: 10px;
  }

  &__background-image {
    z-index: -1;
    height: 132px;
    width: 100%;
    object-fit: cover;
    border: 2px solid #ffffff;
    border-radius: 0 0 20px 20px;

    @include for-phone-only {
      height: 125px;
    }

    &_empty {
      @include fxc;
      flex-direction: column;
      background-color: $bcg-color-d;
      @include p2;

      p:last-child {
        color: $font-color-l;
      }
    }
  }
}
