.app-footer {
  position: relative;
  width: 100%;
  @include fxc;
  flex-direction: column;
  background: $bcg-color;
  margin-top: auto;
  margin-bottom: 50px;

  &__content {
    @include fxc;
    flex-direction: column;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $font-color-d;
    margin-top: 31px;
    a {
      text-decoration: none;
      color: $link-color;
    }
  }
}
