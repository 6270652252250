.product {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &__header {
    height: 186px;
  }

  &__image {
    @include full-abs;
    overflow: hidden;
    @include fxc;
    z-index: 0;
    border-radius: 0 0 20px 20px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__image-dropdown {
    z-index: 1;
    position: absolute;
    top: 20px;
    right: 64px;
  }

  &__close {
    z-index: 1;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  &__content {
    max-width: 1100px;
    height: 100%;
    width: 100%;
    @include fxc;
    justify-content: space-between;
    margin: 30px auto 0;
    padding: 0 20px;
    flex-direction: column;
  }

  &__hint {
    text-align: center;
    @include p2;
    color: $font-color-l;
    margin-top: -50px;
    margin-bottom: 35px;
    @include for-phone-only {
      padding: 0 50px;
    }
  }

  &__form {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 414px;
    margin-top: auto;
    @media screen and (max-width: 950px) {
      padding: 0;
    }

    &__maxQuantity {
      display: flex;
    }

    &__maxQuantity-info {
      height: fit-content;
      transform: translateY(40%);
    }

    &-weight-block {
      display: flex;
      flex-wrap: nowrap;
    }

    &-weight {
      flex-grow: 1;
      margin-right: 10px;
    }

    &-unit {
      flex-grow: 1;
      margin-left: 10px;
    }

    &-description {
      @include for-phone-only {
        margin-top: 25px;
      }
    }
  }

  &__options {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    @include for-phone-only {
      margin-top: 25px;
    }
  }

  &__options-title {
    font-weight: 500;
    font-size: 17px;
    line-height: 30px;
    letter-spacing: 0.02em;
  }

  &__options-collapse {
    display: flex;
    @include for-phone-only {
      margin-bottom: 20px;
    }
    p {
      @include h3;
      flex: 4 1;
    }
  }

  &__options-toggle {
    margin: auto 15px;
  }

  &__options-create{
    display: flex;
    margin-bottom: 10px;
    &_disabled {
      cursor: auto;
      filter: grayscale(100%);
    }
  }

  &__options-info{
    margin: auto 10px auto -30px;
    @include for-phone-only {
      margin: auto 0 auto 10px;
    }
  }

  &__options-list {
    border-bottom: solid 1px #dddddd;
    border-top: solid 1px #dddddd;
    padding: 20px 0;
    &__title {
      @include h2;
      margin-bottom: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__option-groups-list{
    padding: 20px 0;
    &__title {
      @include h2;
      display: flex;
      margin-bottom: 5px;
      align-items: center;
      justify-content: space-between;
      p {
        flex: 1 1;
      }
      &__icon {
        margin-left: 5px;
      }
    }
    &__item {
      border-top: solid 1px #dddddd;
      padding: 20px 0;
      &:last-child{
        border-bottom: solid 1px #dddddd;
      }
    }
  }

  &__button-block {
    margin-top: 29px;
    margin-bottom: 50px;
    width: 100%;
  }

  &__link {
    margin: 10px 0 ;
    cursor: pointer;
    display: flex;
    color: $accent-color;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-decoration: none;
    align-items: center;
    flex: auto;
    p {
      margin-left: 10px;
    }

    &_disabled {
      cursor: default;
    }

    @include filled-icon;


    &_revert {
      flex-direction: row-reverse;

      .icon {
        margin-right: 0;
        margin-left: 18px;
      }
    }
  }

  &__button {
    margin: 0 auto;
    @include for-phone-only {
      margin: auto;
    }
  }
}
