
.counter {
  height: 34px;
  border: 1px solid $link-color;
  border-radius: 50px;
  overflow: hidden;


  svg > circle {
    opacity: 0;
  }

  svg > path {
    fill: $link-color;
  }

  svg > rect {
    fill: $link-color;
  }

  &__content {
    transition: all 0.3s ease-in;
    @include fxc;
    height: 100%;
    width: 32px;
    overflow: hidden;

    &_open {
      width: 90px;
      background-color: $link-color;
      &:hover {
        background-color: $hover-color;
      }

      svg > path {
        fill: $bcg-color!important;
      }

      svg > rect {
        fill: $bcg-color!important;
      }
    }
  }

  &__label {
    color: $link-color;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }

  &__content_open, &__label {
    color: $bcg-color;
  }
}
