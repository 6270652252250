
.order {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;

  &__content {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    flex-grow: 1;
    padding-bottom: 80px;
  }

  &__product-list {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__product {
    margin: 1px;
  }

  &__summary {
    margin: 80px 0;
    display: flex;
    justify-content: space-between;
    @include h3;
    @include for-phone-only {
      margin: 30px 0;
      padding: 0 18px;
    }
  }

  &__button {
    margin: auto;
  }
}

.order-product {
  padding: 18px;
  border-radius: 20px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    pointer-events: none;
  }

  &__name {
    @include p1;
  }

  &__description {
    margin-top: 18px;
    @include p2;
    color: $font-color-l;
  }

  &__weight {
    margin-top: 18px;
    @include p2;
    color: $font-color-l;
  }

  &__option-list {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      color: $font-color-l;
      @include p2();
  }

  &__option {
    margin-top: 2px;
    display: flex;

    &-name {
      margin-left: 10px;
    }

  }

  &__summary {
    margin-top: 18px;
    display: flex;
    justify-content: space-between;
  }
}
