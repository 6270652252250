.sign-in {
  &__form {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 20px 0;

    &-forgot-pass {
      cursor: pointer;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: $font-color-l;
      text-decoration: none;
      letter-spacing: 0.02em;
      margin-top: 20px;
    }
  }

  &__button {
    margin-top: 60px;
    padding: 0;
  }
}
