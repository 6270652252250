
.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  flex-grow: 1;

  &-account {
    &__content {
      margin-top: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      width: 335px;
      @include for-phone-only {
        width: 100%;
        padding: 20px;
      }
    }

    &__title {
      font-weight: 500;
      font-size: 36px;
      line-height: 44px;
      margin-bottom: 40px;
      text-align: center;
      @include for-phone-only {
        display: none;
      }
    }

    &__links {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__button {
      margin-bottom: 20px;
    }

    &__link {
      margin-top: 30px;
      cursor: pointer;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.02em;
      color: $link-color;
      text-decoration: none;
      @include for-phone-only {
        font-size: 16px;
        line-height: 19px;
      }
      text-align: center;
    }

  }
}

.language{
  @include for-phone-only{
    width: 100%;
  }
  margin-top: 50px;
}
