@keyframes animated-number-transform {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}


.animated-number__wrapper {
  @include fxc;
  height: 100%;
  width: 100%;
}

.animated-number__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 18px;
}

.animated-number__nextValue {
  opacity: 0;
  height: 18px;
}

.animated-number__value {
  opacity: 1;
  height: 18px;
}

.animated-number__isAnimating {
  transform: translateY(-100%);
  animation: animated-number-transform 600ms 1 linear forwards;
}

.animated-number__isAnimating .animated-number__nextValue {
  animation: fadeIn 600ms 1 linear forwards;
}

.animated-number__isAnimating .animated-number__value {
  animation: fadeOut 600ms 1 linear forwards;
}
