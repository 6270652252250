
.password-reset {
  @include fxc;
  height: 100%;
  flex-direction: column;
  flex-grow: 1;

  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 335px;
    margin-top: 10vh;
    @include for-phone-only {
      padding: 20px;
    }
    flex-grow: 1;
  }

  &__message {
    margin-bottom: 20px;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.02em;
    color: $font-color-l;
    @include for-phone-only {
      font-size: 20px;
      line-height: 24px;
    }
  }

  &__input {
  }

}
