.cart-widget {
  display: flex;
  touch-action: none;
  align-items: center;
  justify-content: space-evenly;
  color: $bcg-color;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);

  border: 1px solid $link-color;
  background-color: $link-color;
  border-radius: 20px;
  height: 42px;
  min-width: 90px;
  cursor: pointer;
  @include transition(all);

  &:hover {
    background-color: $hover-color;
    svg > rect {
      @include transition(all);
      fill: $hover-color;
      stroke: $hover-color;
    }
  }

  &_empty {
    background: #dddddd;
    border: 1px solid #dddddd;
    border-radius: 20px;

    svg > rect {
      fill: #dddddd;
      stroke: #dddddd;
    }

    &:hover {
      background-color: #dddddd;
      svg > rect {
        fill: #dddddd;
      }
    }
  }

  &__count {
    min-width: 20px;
  }
}
