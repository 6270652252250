.swal2-modal {
  border-radius: 18px!important;
  margin: 0 !important;
  padding: 0 !important;
}

.swal2-header {
  @include for-phone-only {
    padding: 0 !important;
  }
}

.swal2-content {
  margin: 0 !important;
  padding: 0 !important;
}

.swal2-actions {
  padding: 80px 20px 20px 20px !important;
}


#swal2-title {
    padding-top: 80px;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: $font-color-d;
}

#swal2-content {
  height: 100%;
  width: 100% ;
  display: flex !important;
  @include fxc;
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: $font-color-d;
  margin: 0 !important;
  padding: 0 !important;
}


.swal2-confirm, .swal2-cancel {
  height: 50px;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 20px !important;
  letter-spacing: 0.04em !important;
  padding: 10px 10px;
  border-radius: 18px !important;
  flex-grow: 1;
}

.swal2-confirm {
  background-color: #FF006B !important;
}

.swal2-cancel {
  color: $font-color-d !important;
  background-color: #E8E7EA !important;
}
