.start-loader {
  @include full-abs;
  z-index: 1000;
  @include fxc;

  &__content {
    @include fxc;
    @include for-phone-only {
      width: 100%;
    }
  }

  &__animated-logo {
    opacity: 0;
    width: 496px;
    height: 48px;
    animation: 2s linear animated-logo;

    @include for-phone-only {
      width: 248px;
      height: 24px;
    }
  }
}

@keyframes animated-logo {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }

}
