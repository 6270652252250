.start {
  margin: auto;
  height: 100%;
  width: 100%;
  @include fxc;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  overflow: hidden;
  position: relative;

  &__content {
    z-index: 1;
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include for-phone-only {
      justify-content: flex-end;
      margin-bottom: 50px;
      padding: 0 20px;
    }
  }

  &__logo {
    margin: 130px 0px;
    @include for-phone-only {
      margin: 30px 0px;
    }
  }

  &__title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: $font-color-l;
    letter-spacing: 0.02em;
    max-width: 335px;
    text-align: center;
    @include for-phone-only {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__social-btn-list {
    margin-top: 30px;
    @include fxc;
  }

  &__social-btn {
    cursor: pointer;
    margin: 0 10px;
    &:hover {
      svg > circle {
        stroke: none;
      }
      svg > path {
        @include transition(all);
        fill: $bcg-color;
      }
      svg > circle {
        @include transition(all);
        fill: $hover-color;
      }
    }
  }

  &__button {
    margin-top: 30px;
  }

  &__text {
    margin-top: 30px;
    width: 100%;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: $font-color-l;
    @include for-phone-only {
      font-size: 16px;
      line-height: 20px;
      margin-top: 50px;
    }
  }

  &__footer {
    @include fxc;
    width: 100%;
    max-width: 405px;
    text-align: center;
    background-color: $bcg-color;
    margin-bottom: 77px;
    @include for-phone-only {
      margin-bottom: 50px;
      padding: 0 20px 20px 20px;
    }

    p {
      font-size: 14px;
      line-height: 20px;
      color: $font-color-l;
      font-weight: 500;
      letter-spacing: 0.02em;

      a:link,
      a:visited {
        text-underline-position: under;
        color: $accent-color;
      }
    }
  }
}
