.auth-template {
  min-height: 100%;
  @include fxc;
  flex-direction: column;

  &__body {
    position: relative;
    overflow-x: hidden;
    @include fxc;
    flex-direction: column;
    width: 100%;
    padding: 0 20px;
    flex-shrink: 1;
    flex-grow: 1;

    &-content {
      height: 100%;
      width: 100%;
      @include fxc;
      justify-content: flex-start;
      flex-direction: column;
    }
  }

  &__footer {
    text-align: center;
    width: 100%;
    background-color: $bcg-color;
    margin-top: 50px;
    @include for-phone-only {
      padding: 0 20px 20px 20px;
    }


    p {
      font-weight: 300;
      font-size: 14px;
      line-height: 18px;
      color: $font-color-l;

      a:link, a:visited {
        color: $link-color;
      }
    }
  }
}
