.enter-with-email {
  height: 100%;
  align-items: center;
  justify-content: space-around;
  flex-grow: 1;
  position: relative;

  &__logo {
    margin: 55px 0;
    @include for-phone-only {
      margin: 30px 0;
    }
  }

  &__content {
    width: 100%;
    padding: 0 20px;
    margin-top: 80px;
    display: flex;
    flex-grow: 1;
    max-width: 440px;
    flex-direction: column;
    @include for-phone-only {
      margin-top: 30px;
    }
  }

}
