.product-added {
  width: 100%;

  &__content {
    height: 100%;
    margin: 0 auto;
    max-width: 800px;
    padding: 0 20px;
    background-color: $bcg-color;
    padding-top: $headerHeight;
    display: flex;
    align-items: center;
    flex-direction: column;
    @include for-phone-only {
      padding: 25px 20px;
    }
  }

  &__img {
    position: absolute;
    bottom: 50px;
    @include for-phone-only {
      bottom: 0;
    }
  }

  &__title, &__restaurant-name {
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 15px;
    text-align: center;
    @include for-phone-only {
      font-size: 24px;
      line-height: 29px;
    }
  }

  &__title {
    position: relative;
    margin: 60px 0;
    @include for-phone-only {
      margin: 10px 0;
    }
  }

  &__text {
    text-align: center;
    color: $font-color-l;
    letter-spacing: 0.02em;
    font-size: 28px;
    line-height: 36px;

    @include for-phone-only {
      font-size: 20px;
      line-height: 24px;
    }
  }

  &__btn-block {
    display: flex;
    margin-top: 100px;
    @include for-phone-only {
      flex-direction: column;
    }
  }

  &__btn {
    margin: 10px 10px;
    width: 335px;
  }
}
