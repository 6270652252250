.info-page {
  overflow: hidden;
  @include fxc;
  height: 100%;
  width: 100%;
  flex-grow: 1;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $font-color-l;
    text-align: center;

    h1 {
      font-size: 80px;
      line-height: 110px;
      font-weight: 700;
    }

    h2 {
      margin: 50px 0;
      font-size: 36px;
      line-height: 40px;
    }

    z-index: 10;
  }

}
