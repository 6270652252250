.restaurant-options {

  &__content {
    margin-bottom: 50px;

    &__link {
      cursor: pointer;
      transform: translateX(40%);
      z-index: 5;
      position: fixed;
      bottom: 100px;

      @include for-phone-only {
        left: 20px;
        bottom: 70px;
      }

      @include filled-icon;

      .icon {
        margin-right: 10px;
      }

      &_revert {
        flex-direction: row-reverse;

        .icon {
          margin-right: 0;
          margin-left: 18px;
        }
      }
    }
  }
}

