$desktop-image-size: 120px;

.restaurant-menu-product {
  width: 100%;
  margin-bottom: 3px;
  background-color: $white;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  z-index: 0;
  padding: 10px;
  display: flex;
  @include for-phone-only {
    flex-direction: column;
  }

  &__wrapper {
    width: 100%;
    display: flex;
  }

  &_with-image {
    @include for-phone-up {
      .restaurant-menu-product__content {
        width: calc(100% - #{$desktop-image-size});
      }
    }
    @include for-phone-only {
      .restaurant-menu-product__wrapper {
        width: calc(100% - #{$desktop-image-size});
      }
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    pointer-events: none;
    z-index: -1;
  }

  &__image-wrapper {
    @include fxc;
    height: $desktop-image-size;
    width: $desktop-image-size;
    min-width: $desktop-image-size;

    overflow: hidden;

    @include for-phone-only {
      margin: 0;
      border: 4px solid #ffffff;
      border-radius: 20px;
      flex-shrink: 1;
    }
  }

  &__image {
    border-radius: 10px;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__content {
    width: 100%;
    display: flex;
    padding: 8px;
    flex-direction: column;
    justify-content: space-between;

    @include for-phone-only {
      flex: 1 0 auto;
      justify-content: normal;
    }
  }

  &__name {
    @include p2;

    &_text-concat {
      position: relative;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;

      &-line3 {
        line-clamp: 3;
        -webkit-line-clamp: 3;
      }
      &-line2 {
        line-clamp: 2;
        -webkit-line-clamp: 2;
      }
      &-line1 {
        line-clamp: 1;
        -webkit-line-clamp: 1;
      }
    }

    &_reduction {
      @include text-reduction;
    }
  }

  &__description {
    @include p2;
    margin-top: 10px;
    color: $font-color-l;
    white-space: pre-line;

    &_reduction {
      @include text-reduction;
    }

  }

  &__footer {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__price {
    @include p1;
  }
}
