.restaurant-delivery {
  &__block {
    padding: 30px 20px;
    position: relative;
    background-color: $white;
    border-radius: 20px;
    margin-bottom: 3px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
      border-radius: 20px;
      pointer-events: none;
    }
  }

  &__block-row {
    display: flex;
    width: 100%;
  }

  &__info-button {
    position: absolute;
    top: 40px;
    right: 20px;
  }

  &__save-button {
    margin: 50px auto 60px;
  }
}