@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

* {
  font-family: 'Montserrat', sans-serif;
}

html,
body,
#root {
  display: flex;
  flex-grow: 1;
  min-height: 100%;
  width: 100%;
  font-size: 18px;
  line-height: 20px;
  color: $font-color-d;
  overflow: hidden;
}

.app-content {
  width: 100%;
  background: $bcg-color;
  display: flex;
  position: relative;
  flex-direction: column;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.app-content::-webkit-scrollbar {
  width: 0;
}

.page {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: $container-width;
  margin: 0 auto;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: $font-color-d;
  -webkit-box-shadow: unset;
  transition: background-color 5000s ease-in-out 0s;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.error-box {
  width: 100%;
  color: #ff006b;
  font-size: 0.6em;
  margin: 10px;

  &_center {
    text-align: center;
  }
}

.no-translate {
  transform: none !important;
}
