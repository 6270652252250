section.route-section {
  position: absolute;
  display: flex;
  min-height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
}

.route-section__content {
  display: flex;
  flex-grow: 1;
  min-height: 100%;
  width: 100%;
}