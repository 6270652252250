.restaurant-opportunity-modal {

  &__body {
    width: 100%;
    padding: 0 20px;
  }

  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
  }

  &__item {
    @include h2;
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 70px;
    border-top: 1px solid $border-color;
    text-decoration: none;
    color: $font-color-d;

    &_temp-text-class {
      @include p2;
      margin-left: 15px;
    }

    &:last-child {
      border-bottom: 1px solid $border-color;
    }
  }

  &__links {
    margin-bottom: 50px;
  }

  &__link {
    @include h2;
    @include fxc;
    height: 70px;
    color: $font-color-d;
    width: 100%;
    text-underline-position: under;
  }
}
