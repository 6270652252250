.textarea {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  min-height: 50px;

  &__error {
    .textarea__wrapper {
      border-bottom: 1px solid #ff006b;
    }
  }

  &__label {
    @include p2;
    color: $font-color-l;
    margin-bottom: -10px;
    z-index: 3;
  }

  &__component {
    z-index: 1;
    height: 100%;
    width: 100%;
    padding: 10px 0;
    font-weight: 500;
    font-size: 16px;
    resize: none;
    border-bottom: 1px solid $border-color;
    overflow-y: hidden !important;

    -webkit-appearance: none;

    @include placeholder-a-left {
      text-align: center;
      vertical-align: center;
      font-size: 16px;
      line-height: 80px;
      letter-spacing: 0.02em;
      color: rgba(22, 11, 36, 0.5);
    }

    &:focus {
      outline: none;
    }

    &:invalid {
      color: #ff006b;
    }
  }

  &__required-message {
    margin-top: 5px;
    margin-left: 8px;
    width: 100%;
    font-size: 0.6em;
    color: $font-color-l;
  }

  &__error-message {
    margin-top: 5px;
    margin-left: 8px;
    width: 100%;
    color: #ff006b;
    font-size: 0.6em;
  }
}
