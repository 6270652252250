.ReactModalPortal {
  z-index: 10;
}

.ReactModal__Content {
  padding: 0!important;
}

.app-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-height: 100%;
  width: 100%;
  padding: 20px;

  @include for-phone-only {
    padding: 20px 0;
  }


  &__close-icon {
    position: absolute;
    right: 80px;
    top: 50px;
    @include for-phone-only {
      top: 20px;
      left: 20px;
    }
  }
}