.toggle-block {
  display: flex;
  align-items: center;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 64px;
  min-width: 64px;
  height: 32px;
  border-radius: 15px;

  &:hover {
    .toggle__input + .slider {
      //border: 1px solid #E8E7EA;
    }

    .toggle__input + .slider:before {
      //border: 1px solid #E8E7EA;
    }

    .toggle__input:checked + .slider {
      //background-color: $link-color;
      border: 1px solid $link-color;
    }

    .toggle__input:checked + .slider:before {
      //border: 2px solid $link-color;
    }
  }

  &__input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: $link-color;
      border: 1px solid $link-color;

      .toggle {
        border-color: $link-color
      }
    }

    &:checked + .toggle {
      border: 1px solid $link-color;
    }


    // FOCUS UNCHECKED
    &:focus + .slider:before {
      border: 1px solid #E8E7EA;
    }

    &:focus + .slider {
      border: 1px solid #E8E7EA;
      box-shadow: 0 0 0 2px #a5a4a7;
      background-color: #E8E7EA;
      border-radius: 100px;
    }


    // FOCUS CHECKED
    &:checked:focus + .slider {
      border-color: $link-color;
      box-shadow: 0 0 0 2px #a5a4a7;
      background-color: $link-color;
      border-radius: 100px;
    }

    &:checked + .slider:before {
      transform: translateX(32px);
      border-color: $link-color;
    }


  }

}


/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #E8E7EA;
  border: 1px solid #E8E7EA;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 28px;
  width: 28px;
  left: 0;
  top: 0;
  background-color: white;
  transition: .4s;
  border: 1px solid #E8E7EA;
}


/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}


.slider.round:before {
  border-radius: 50%
}

.toggle-label {
  color: #8B8592;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  padding-left: 20px;
}

.toggle-label_active {
  color: #8B8592;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  padding-left: 20px;
}


