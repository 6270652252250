.select {
  display: flex;
  height: 44px;
  width: 100%;
  position: relative;
  align-items: center;
  color: #8B8592;
  border-bottom: 1px solid #E8E7EA;
  margin-bottom: 25px;
  font-size: 16px;

  &__error {
    border-bottom: 1px solid #FF006B;
  }

  &__label {
    position: absolute;
    top: -12px;
    @include p2;
  }

  &__value {
    width: 100%;
    color: $font-color-d;
  }

  &__arrow {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
  }

  &__dropdown {
    max-height: 0;
    z-index: 10;
    position: absolute;
    top: 43px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0 4px 8px rgba(22, 11, 36, 0.15);
    border-radius: 0 0 8px 8px;
    overflow: auto;
    @include transition(max-height);
    &_open {
      max-height: calc(50px * 4);
    }
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background: $bcg-color
    }
    &::-webkit-scrollbar-thumb {
      background: #afafaf;
      border: 2px solid rgba(0, 0, 0, 0.08);
      border-radius: 15px;
    }
  }

  &__error-message {
    position: absolute;
    top: 50px;
    width: 100%;
    color: #FF006B;
    font-size: 0.6em;
  }

  &__option {
    position: relative;
    display: flex;
    align-items: center;
    //color: $font-color-d;
    height: 50px;
    padding-left: 8px;
    transition: all ease 0.3s;

    &:before {
      background: linear-gradient(270deg, rgba(139, 133, 146, 0) 0%, rgba(139, 133, 146, 0.05) 50%, rgba(139, 133, 146, 0) 100%);
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      top: 0; left: 0;
      opacity: 0;
      width: 100%;
      @include transition(opacity)
    }
    &_active, &:hover {
      &:before {
        opacity: 1;
      }
    }
  }

}
