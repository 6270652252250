.restaurant-header {
  min-height: 160px;
  width: 100%;
  z-index: 1;
  position: relative;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__left-icons {
    display: flex;
    position: absolute;
    z-index: 1;
    top: 20px;
    left: 20px;
  }

  &__right-icons {
    display: flex;
    position: absolute;
    z-index: 1;
    top: 20px;
    right: 20px;
  }

  &__title {
    margin-top: 30px;
    padding: 0 20px;
    @include h1;
  }

  &__logo {
    position: relative;
    z-index: 1;
    margin-top: 50px;

    &_with-bg-image {
      margin-top: 120px;
    }
  }

  &__background-image {
    position: absolute;
    height: 186px;
    width: 100%;
    object-fit: cover;
    border: 2px solid #ffffff;
    border-top: unset;
    border-radius: 0 0 20px 20px;

    &_empty {
      @include fxc;
      flex-direction: column;
      background-color: $bcg-color-d;
      @include p2;
      cursor: pointer;

      p:last-child {
        color: $font-color-l;
      }
    }
  }
}
