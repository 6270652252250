.modify-menu-controls {
  &__list {
    position: absolute;
    display: block;
    bottom: 40px;
    width: 170px;
    transition: height, opacity 1s ease-in;

    &_show {
      display: block;
      opacity: 1;
    }

    &_hide {
      animation: hideList 1 1s linear forwards;
    }

    &-item {
      cursor: pointer;
      margin: 10px 0;
      @include fxc;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      background-color: $link-color;
      min-height: 40px;
      color: $bcg-color;
      border: 1px solid $link-color;
      border-radius: 20px;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
      @include transition(background-color);
      &:hover {
        background-color: $hover-color;
      }

      @for $i from 1 through 5 {
        &:nth-child(#{$i}) {
          --animate-duration: #{1 / $i}s;
        }
      }
    }
  }
}

@keyframes hideList {
  50% {
    opacity: 0;
  }
  100% {
    height: 0;
  }
}
