.localization {
  &__content {

  }
  &__language-settings-list {
    background: #FFFFFF;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 6px;
    filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));

    &-title {
      @include h3;
      color: #160B24;
      margin-left: 15px;
      flex: 2 1;
    }

    &-header {
      display: flex;
      width: 100%;
      align-items: center;
      padding: 24px 21px;
    }
    &-content {
      width: 100%;
    }
    &-item {
      padding: 15px 20px;
      background: #FFFFFF;
      width: 100%;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      border-radius: 20px;
      &-header {
        display: flex;
        width: 100%;
        align-items: center;
      }
      &-title {
        @include p1;
        color: #160B24;
        margin-right: 20px;
        @include for-phone-only {
          flex: 2 1;
          margin-right: 0;
        }
      }
    }
  }
  &__add-content-languages{
    &_out {
      width: 180px;
      background: $bcg-color;
      justify-content: center;
      border-radius: 20px;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
      min-height: 32px;

      p {
        @include p2;
        margin-right: 20px;
      }
    }
    display: flex;
    align-items: center;
    p {
      margin-left: 10px;
      cursor: pointer;
    }
  }
  &__content-languages{
    padding: 20px 12px;
    width: 100%;
    display: flex;
    &-item {
      @include p2;
      background: #FAFAFA;
      border: 1px solid #DDDDDD;
      border-radius: 4px;
      padding: 7px 16px;
      width: fit-content;
      margin-right: 10px;
    }
  }
}
