.confirm-phone-modal {
  height: 100% ;
  width: 100% ;

  &__body {
    @include fxc;
    flex-direction: column;
    height: 100%;
    flex: 1;
    max-width: 400px;
    margin: 0 auto;
  }

  &__text {
    @include p2;
    margin-bottom: 60px;
    text-align: center;
  }

}
