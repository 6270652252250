.info {
  &__background {
    z-index: 5;
    position: fixed;
    @include full;
    background-color: rgba(0, 0, 0, 0.2);
  }

  &__wrapper-modal {
    height: 100%;
    width: 100%;
    position: absolute;
  }
  &__modal {
    position: absolute;
    background: #ffffff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    width: calc(100% - 40px);
    height: fit-content;
    max-width: $container-width;
    left: 50%;
    transform: translateX(-50%);
    padding: 30px 20px 26px;
  }
  &__title {
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #160b24;
    margin-bottom: 20px;
  }

  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #160b24;
  }
}
