.restaurant-menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: $container-width;
  margin: 0 auto;

  &__content {
  }

  &__header {
    max-width: $container-width;
    margin: 0 auto;
  }

  &__menu-name {
    @include h2;
    text-align: center;
    margin-bottom: 65px;
    @include for-phone-only {
      margin-bottom: 30px;
      padding: 0 20px;
    }
  }

  &__category {
    border-bottom: 1px solid #dddddd;
    &:first-child {
      // TODO FIX THIS SHIT
      // refactor RestaurantMenuEditableLabel end remove from it classes restaurant__menu-list-item!
        border-top: 1px solid #dddddd;
    }
    &-emptytext {
      @include for-phone-only {
        padding-left: 20px;
      }
    }
  }

  &__category-products-list {
    scroll-padding-top: 100px;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 21px;
    @include transition(all);

    &_hide {
      animation: hideProductsList 0.3s linear forwards;
    }
    &_show {
      animation: showProductsList 0.3s linear forwards;
    }
  }

  &__category-item {
    padding: 20px 0;
    border-top: 1px solid $border-color;
    &:last-child {
      border-bottom: 1px solid $border-color;
    }
  }

  &__category-name {
    @include for-phone-only {
      padding: 0 20px;
    }
  }



  &__product {
    margin: 2px;
  }

  &__product-icons {
    display: flex;
    .icon {
      margin-left: 10px;
    }
  }

  &__restaurant-preview-qr-block {
    @include for-phone-only {
      padding: 0 20px;
    }
  }

  &__create-category-label {
    border-bottom: 1px solid #dddddd;
  }

  &__link {
    margin: 10px 0 30px 20px;

    &_first {
      margin-top: 40px;
    }
  }

  &__plus-button {
    z-index: 5;
    position: fixed;
    bottom: 60px;
    margin-left: 20px;
    border-radius: 50%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    transition: 1s bottom;

    .icon svg {
      width: 40px;
      height: 40px;
    }

    @include filled-icon;

    @include for-phone-only {
      margin-left: 0;
      left: 20px;
      bottom: 30px;
    }
  }
}

.restaurant-menu-user {
  margin-bottom: 80px;
  @include for-phone-only {
    margin-bottom: 60px;
  }

  &__menu-name {
    margin-bottom: 60px;
    @include for-phone-only {
      margin-bottom: 75px;
    }
  }

  &__category-name {
    padding: 20px 0 30px;
    @include for-phone-only {
      padding: 20px 20px 30px;
    }
    &_not-displayed {
      display: none;
    }
  }

  &__icons {
    display: flex;
  }
}

@keyframes hideProductsList {
  0% {
    opacity: 1;
    overflow: unset;
  }
  100% {
    height: 0;
    overflow: hidden;
    padding: 0;
    opacity: 0;
  }
}

@keyframes showProductsList {
  0% {
    opacity: 0;
    overflow: hidden;
  }
  100% {
    opacity: 1;
    overflow: unset;
    height: 100%;
  }
}
