.restaurant {
  align-items: center;
  flex-grow: 1;

  &__content {
    width: 100%;
    background-color: $bcg-color;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding: 0 20px;
    margin-top: 25px;
    @include for-phone-only {
      margin-top: 20px;
    }
  }
  &__upper-controls {
    text-align: center;
    width: 100%;
    padding: 0 20px;
  }
  &__scroll-to-pay {
    margin-left: 5px;
    color:$font-color-l;
    @include p2;
    cursor: pointer;
    text-decoration: underline;
    text-underline-position: under;
    &_green {
      color: $link-color;
    }
  }
  &__title {
    word-break: break-word;
    @include h1;
    text-align: center;

    &_no-images {
      margin-top: 100px
    }
  }

  &__title-input {
    margin: 25px 0;
  }

  &__empty-text {
    margin: auto;
    position: relative;
    padding-top: 50px;
    text-align: center;
    color: $font-color-l;
    letter-spacing: 0.02em;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;

    @include for-phone-only {
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
    }

    &:before {
      position: absolute;
      top: 5px;
      right: 50%;
      transform: translateX(50%);
      content: url("data:image/svg+xml,%3Csvg width='27' height='27' viewBox='0 0 27 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M26 12.3572V13.5071C25.9985 16.2027 25.1256 18.8255 23.5117 20.9844C21.8977 23.1434 19.6291 24.7227 17.0442 25.487C14.4593 26.2513 11.6966 26.1595 9.16809 25.2254C6.6396 24.2912 4.48082 22.5648 3.0137 20.3035C1.54658 18.0422 0.849739 15.3672 1.0271 12.6775C1.20445 9.98786 2.24651 7.42756 3.99785 5.37849C5.74919 3.32943 8.11598 1.90138 10.7452 1.30734C13.3745 0.713292 16.1253 0.985076 18.5875 2.08215M26 4L13.6923 16L10 12.4036' stroke='%23160B24' stroke-width='2' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
  }

  &__menu {
    width: 100%;
  }

  &__menu-list {
    border-bottom: 1px solid #dddddd;
  }

  &__menu-list-item {
    cursor: pointer;
    min-height: 70px;
    display: flex;
    align-items: center;
    border-top: 1px solid #dddddd;
  }

  &__option {
    margin-bottom: 35px;
    @include fxc;
    &-info {
      margin-left: 6px;
      margin-right: 16px;
      @include for-phone-only {
        margin-right: 6px;
        order: 2;
      }
    }
  }

  &__accent-text {
    @include h3;
    color: $accent-color;

    margin-right: auto;
    text-underline-position: under;
  }

  &__preview-text-area {
    margin-top: 25px;
    margin-bottom: 10px;
    * {
      border-bottom: none;
    }
  }

  &__restaurant-link-block {
    margin-top: 30px;
    display: grid;
    width: 100%;
    grid-template-columns: 32px 1fr 32px;
    align-items: center;
    margin-bottom: 50px;
    p {
      @include p1;
      margin: 0 10px;
      line-break: anywhere;
    }
  }

  &__link {
    margin: 29px 0;
    cursor: pointer;
    display: flex;
    color: $accent-color;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-decoration: none;
    align-items: center;

    @include filled-icon;

    .icon {
      margin-right: 10px;
    }

    &_revert {
      flex-direction: row-reverse;

      .icon {
        margin-right: 0;
        margin-left: 18px;
      }
    }
  }

  &__menu-link {
    margin-top: 20px;
    margin-bottom: 60px;
  }

  &__info {
    margin-top: 50px;
    margin-bottom: 77px;
    @include for-phone-only {
      margin-bottom: 89px;
    }
  }

  &__preview-qr-block {
    margin: 80px 0 115px;
  }
}

.restaurant-preview-qr-block {
  @include fxc;
  flex-direction: column;

  @include for-phone-only {
    margin: 71px 0 59px;
  }

  &__text {
    margin-top: 30px;
    margin-bottom: 60px;
    text-align: center;
    max-width: 414px;
    color: $font-color-d;
    opacity: 0.7;

    @include p2;
    @include for-phone-only {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 50px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.restaurant-menu-list-item {
  cursor: pointer;
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__button {
    transform: rotateY(180deg);
  }
  &__input {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.01em;
    @include for-phone-only{
      margin: 0 20px;
    }

    @media (max-width: 350px) {
      .input__required-text {
        display: none;
      }
    }

    color: #160b24;

    .input__wrapper {
      border: none;
      min-height: 50px;
    }
  }

  &__icons {
    display: flex;
  }

  &__hide-icon {
    margin-right: 10px;
  }

  &__icon-hide {
    width: 32px;
    height: 32px;
    margin-right: 10px;
  }

  &__icon-arrow {

    margin-right: 10px;
    & svg > circle {
      transition: none;
    }
  }

  &__name {
    @include h2;
  }
}

.restaurant-info {
  &__preview {
    text-align: center;
    margin-bottom: 80px;
  }

  &__elements {
    display: grid;
    grid-gap: 40px 20px;
    grid-template-columns: 1fr 1fr;
    @include for-phone-only {
      grid-template-columns: 1fr;
    }
  }

  &__element {
    align-items: center;
    display: grid;
    grid-template-columns: 30px 1fr;
    grid-gap: 20px;
    p, a {
      text-decoration: none;
      @include p1;
      color: $font-color-d;
    }
  }
}
