.seo {

  @mixin baseForm {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 550px;
    margin: auto;
    @media screen and (max-width: 950px) {
      padding: 40px 20px;
    }
  }

  &__content {
    display: flex;
    width: 100%;
    padding: 0 20px;
    flex-direction: column;
  }

  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
  }

  &__item {
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__form-desktop {

    @include baseForm;

    &__button {
      margin: 50px auto;
    }

  }

  &__form-mobile {

    @include baseForm;

    flex: 1;

    &__content {
      flex: 1;
    }

    &__button {
      margin: 0 auto;
    }
  }
}

