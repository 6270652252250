.subscription-block {
    filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15));
    width: 100%;
    display: flex;
    margin-bottom: 50px;
    flex-direction: column;
  &__container {
    background-color: $bcg-color;
    margin-top: 3px;
    display: flex;
    padding: 25px 20px;
    border-radius: 20px;
    cursor: pointer;
  }
  &__text {
    flex-grow: 1;
  }
  &__description {
    margin: 10px 0 0 0;
    @include h3;
    &-result {
      @include p1;
      text-align: center;
    }
  }
  &__title {
    color: $link-color;
    @include h2;

    &-result {
      @include h1;
    }

    &_error {
      color: #FF0000;
      display: flex;
      .icon {
        margin-top: 4px;
        margin-right: 15px;
        svg path{
          fill: #FF0000;
        }
      }
    }
  }
  &__pay-button {
    position: relative;
    top: 15px;
    transform: rotateY(180deg);
  }

}