.contacts {

  @mixin baseForm {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 550px;
    margin: auto;
    @media screen and (max-width: 950px) {
      padding: 40px 20px;
    }
  }

    &__content {
      display: flex;
      width: 100%;
      padding: 0 20px;
    }

    &__list {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    &__item {
      width: 100%;
      display: flex;
      align-items: center;
    }

    &__form-desktop {

      @include baseForm;

      &__button {
        margin: 50px auto;
      }

    }

    &__form-mobile {

      @include baseForm;

      flex: 1;

      &__content {
        flex: 1;
      }

      &__button {
        margin: 0 auto;
      }
    }
}

.contacts-item {
  min-height: 70px;
  border-top: 1px solid $border-color;

  &:last-child {
    border-bottom: 1px solid $border-color;
  }

  &_empty {
    color: $disable-color;
    cursor: pointer;

    .contacts-item__icon {
      svg path {
        fill: $disable-color;
      }
    }
  }

  &__icon {
    svg {
      width: 20px;
    }
    margin-right: 18px;
  }

  &__text {
    @include p1;
    white-space: pre-wrap!important;
  }

  &__more-icon {
    margin-left: auto;
  }

}

.contacts-schedule {
  &__item {
    display: grid;
    grid-template-columns: 1fr 80px 80px;
    align-items: center;
    margin-bottom: 24px;
    width: 100%;

    .select {
      margin-bottom: 0!important;
    }
  }
}
