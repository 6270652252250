.editable-label {
  display: flex;
  align-items: center;
  position: relative;
  margin: 25px 0;

  &__component {
    padding-right: 40px;
    @include p1;
  }

  &__icon {
    position: absolute;
    cursor: pointer;
    margin-left: 10px;
    right: 0;

    &_apply {
      top: 15px;
    }

    svg > circle {
      stroke: $border-color;
    }
  }
}
