.sync-button {
  margin: 30px auto;

  &__syncing {
    svg {
      animation: spin 1.75s linear infinite;
    }
  }

  &__syncing-text {
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    &_resync {
      animation: 1s ease-in slidein;
    }
  }

  &__syncing-success {
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      animation: 1.5s ease-in slidein;
    }
  }
}

@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
@keyframes slidein {
  0% { opacity: 0; }
  60%   { opacity: 1; }
  100%   { opacity: 1; }
}
