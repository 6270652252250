.dropdown-menu {
  position: relative;
  text-align: center;
  &__icon {
    &:hover {
      svg > path {
        fill: $link-color;
        fill-opacity: 1;
      }
    }
  }

  &__label {
    @include p2;
  }

  &__background {
    z-index: 5;
    position: fixed;
    @include full;
    background-color: rgba(0, 0, 0, 0.2);
  }

  &__dropdown {
    background-color: $bcg-color;
    position: absolute;
    max-height: 0;
    min-width: 300px;
    top: 42px;
    right: 0;
    opacity: 0;
    border-radius: 16px;
    z-index: 10;
    overflow: hidden;
    @include transition(all);
    box-shadow: 0 4px 8px rgba(22, 11, 36, 0.15);
    &_top-direction {
      bottom: 42px;
      top: unset;
    }
    &_left-direction {
      left: 0;
      right: unset;
    }
    &_language {
      min-width: 180px;
    }
    &_open {
      opacity: 1;
      max-height: fit-content;
    }
  }

  &__option {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #8b8592;
    letter-spacing: 0.02em;
    min-height: 50px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding: 8px 10px;

    &:before {
      background: linear-gradient(
        270deg,
        rgba(139, 133, 146, 0) 0%,
        rgba(139, 133, 146, 0.05) 50%,
        rgba(139, 133, 146, 0) 100%
      );
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      @include transition(opacity);
    }

    &_active,
    &:hover {
      &:before {
        opacity: 1;
      }
    }

    .icon {
      width: 32px;
      margin-right: 10px;
    }
  }
  &__option-icon {
    & svg > circle {
      stroke: none;
    }
  }
}
