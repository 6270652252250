.app-header {
  height: $headerHeight;
  width: 100%;
  z-index: 1;
  background: transparent;
  position: relative;

  @include for-phone-only {
    position: relative;
    height: $m-headerHeight;
  }

  &-image {
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__container {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 20px 20px 0;
  }

  &__left-icon {
    position: absolute;
    left: 10px;
    top: 50px;
    @include for-phone-only {
      top: 20px;
      left: 20px;
    }
  }

  &__title {
    display: flex;
    justify-content: center;
    @include h2;
    width: 100%;
    max-width: 80%;
    position: absolute;
    left: 60px;
    top: 50px;
    @include for-phone-only {
      text-align: center;
      top: 60%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__right-icon {
    position: absolute;
    top: 50px;
    right: 10px;
    @include for-phone-only {
      top: 20px;
      right: 20px;
    }
  }
}
