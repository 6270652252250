$size: 125px;

.image-header {
  input[type='file'] {
    visibility: hidden;
    position: absolute;
  }

  @include fxc;
  height: $size;
  width: $size;
  border: 2px solid #DDDDDD;
  border-radius: 50%;

  &__text {
    @include fxc;
    border-radius: 50%;
    height: 100%;
    width: 100%;
    background-color: $bcg-color-d;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: $font-color-l;
    @include for-phone-only {
      font-size: 14px;
    }
    cursor: pointer;
  }

  &__icon {
    cursor: pointer;
    position: absolute;
    right: -15px;
  }

  &__img-container {
    @include fxc;
    border-radius: 50%;
    height: 100%;
    width: 100%;
    position: absolute;
    overflow: hidden;
  }

  &__img {
    position: absolute;
    height: $size;
    object-fit: cover;
    width: 100%;
  }
}
