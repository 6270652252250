@import '~animate.css/animate.css';

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 600ms linear;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 600ms linear;
}



.fade-down-enter {
  opacity: 0;
  transform: translateY(-30px);
}
.fade-down-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 1s;
}
.fade-down-exit {
  transform: translateY(0);
  opacity: 1;
}
.fade-down-exit-active {
  opacity: 0;
  transform: translateY(30px);
  transition: all 1s;
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}

@keyframes modalFadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 30%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}


@keyframes modalFadeOutDown {
  0% {
    opacity: 1;
    transform: none;
  }
  100% {
    opacity: 0;
    transform: translate3d(0, 30%, 0);
  }
}


.modalFadeInUp {
  animation-name: modalFadeInUp;
}
.modalFadeOutDown {
  animation-name: modalFadeOutDown;
}
