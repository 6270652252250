.product-options {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;

  &__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 670px;
    width: 100%;
    margin-top: 5vh;
    background-color: $bcg-color;
    padding: 10px;
    @include for-phone-only {
      margin-top: 30px;
      padding: 0;
    }
  }

  &__link {
    margin: 29px 0;
    cursor: pointer;
    display: flex;
    color: $accent-color;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-decoration: none;
    align-items: center;
    margin-left: 10px;

    @include filled-icon;

    .icon {
      margin-right: 10px;
    }

    &_revert {
      flex-direction: row-reverse;

      .icon {
        margin-right: 0;
        margin-left: 18px;
      }
    }
  }

  &__list {
    margin-bottom: 30px;

    &-title {
      margin: 0 0 20px 20px;
      @include h3;
    }
    &-description{
      margin: 0 0 20px 20px;
      color: $font-color-l;
      @include p2();

      &_required {
        margin-bottom: 0;
      }
    }
  }

  &__save-btn {
    margin: 50px auto 50px auto;
  }

  &__summary {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 20px;
    &-text {
      color: $font-color-l;
      @include p2();
    }

    &-price {
      margin-top: 10px;
      @include p1();
    }
  }
}

.options-list {
  display: flex;
  flex-direction: column;
}
