  .button {
    cursor: pointer;
    position: relative;
    width: 100%;
    max-width: 335px;
    border-radius: inherit;
    background-color: $link-color;
    color: $bcg-color;
    padding: 14px 18px;
    font-weight: 500;
    font-size: 20px;
    border: none;
    @include transition(background-color);

    &__wrap {
      overflow: hidden;
      position: relative;
      max-width: 335px;
      border-radius: 50px;
      width: 100%;
      padding: 4px;
    }

    &:disabled {
      background-color: #8b8591;
      box-shadow: 0 0 0 4px #8b8591;
    }

    &:hover:not([disabled]), &:focus {
      outline: none;
      background-color: $hover-color;
    }
  }
.secondary_button {
  cursor: pointer;
  position: relative;
  width: 100%;
  max-width: 335px;
  border-radius: inherit;
  border: 1px solid #DDDDDD;
  background-color: #FAFAFA;
  color: $font-color-d;
  padding: 14px 18px;
  font-weight: 500;
  font-size: 20px;
  @include transition(background-color);

  &__wrap {
    overflow: hidden;
    position: relative;
    max-width: 335px;
    border-radius: 50px;
    width: 100%;
    padding: 4px;
  }

  &:disabled {
    border: none;
    background-color: #FAFAFA;
    color: $font-color-l;
  }

}