.checkbox {
  margin: 0 !important;
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: relative;
  @include transition(all);
  border: none;
  border-radius: 0;
  overflow: hidden;

  &.hidden{
    opacity: 0.5;
  }

  &:before {
    content: '';
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 2px;
    border: 1px solid $link-color;
    background: $bcg-color;
  }

  &:after {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='14' viewBox='0 0 18 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.9999 10.7L1.7999 6.49998L0.399902 7.89998L5.9999 13.5L17.9999 1.49998L16.5999 0.0999756L5.9999 10.7Z' fill='white'/%3E%3C/svg%3E%0A");
    @include full;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    background-color: $link-color;
    border-radius: 2px;
  }

  &:not(:checked) {
    &:after {
      opacity: 0;
    }
  }

  &:disabled:not(:checked) {
    &:before {
      box-shadow: none;
      border-color: #222222;;
      background-color: $bcg-color;
    }
  }

  &:checked {
    &:after {
      opacity: 1;
      color: #222222;;
    }
  }

  &:disabled:checked {
    &:after {
      background: #FFFFFF;
    }
  }

  &:disabled {
    color: #222222;;
  }

  &:checked:focus, &:not(:checked):focus {
    &:before {
      border: 1px solid rgb(212, 213, 215);
    }
  }

}
