.control-center {
  @mixin baseForm {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 550px;
    margin: auto;

    @media screen and (max-width: 950px) {
      padding: 40px 20px;
    }
  }

  &__staff {
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 70px;
    border-top: 1px solid $border-color;
    justify-content: space-between;
    cursor: pointer;

    @include for-phone-only {
      padding: 0 20px;
    }
  }
  &__staff-text {
    @include h3;
  }

  &__staff-icon {
    transform: rotateY(180deg);
    margin-right: 10px;
  }


  &__control-center-content {
    display: flex;
    width: 100%;
    padding: 0 20px;
    flex-direction: column;
  }

  &__no-staff {
    @include p2;
    @include for-phone-only {
      margin: 20px 20px 30px 20px;
    }
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: justify;
  }

  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    &_disabled {
      * {
        color: $font-color-l !important;
        cursor: default !important;
      }
    }
  }

  &__control {
    &:last-child {
      // TODO FIX THIS SHIT
      // refactor RestaurantMenuEditableLabel end remove from it classes restaurant__menu-list-item!
      border-bottom: 1px solid #dddddd;
    }
  }

  &__control-emptytext {
    @include for-phone-only {
      padding-left: 20px;
    }
  }
  &__control-list-item {
    min-height: 70px;
    display: flex;
    align-items: center;
    border-top: 1px solid #dddddd;
    cursor: pointer;
    justify-content: space-between;
    @include for-phone-only {
      padding: 0 20px;
    }
  }
  &__list-item-icons {
    display: flex;
  }
  &__list-item-icon {
    width: 32px;
    height: 32px;
    margin-right: 10px;

   &_arrow {
     & svg > circle {
       transition: none;
     }
   }

   &_reverted {
     transform: rotateY(180deg);
   }
  }

  &__list-item-name {
    @include h3;
  }

  &__list-item-content {
    scroll-padding-top: 100px;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 21px;
    @include transition(all);

    &_hide {
      animation: hideControl 0.3s linear forwards;
    }

    &_show {
      animation: showControl 0.3s linear forwards;
    }
  }

  @keyframes hideControl {
    0% {
      opacity: 1;
      overflow: unset;
    }
    100% {
      overflow: hidden;
      padding: 0;
      opacity: 0;
      height: 0;
    }
  }

  @keyframes showControl {
    0% {
      opacity: 0;
      overflow: hidden;
    }
    100% {
      opacity: 1;
      overflow: unset;
      height: fit-content;
    }
  }
}
