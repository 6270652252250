.staff {

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 20px;
  }

  &__text {
    @include p1;
  }

  &__more-icon {
    margin-left: auto;
  }

  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__item {
    width: 100%;
    display: flex;
    align-items: center;
  }

}

.staff-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid $border-color;
  position: relative;
  &:last-child {
    border-bottom: 1px solid $border-color;
  }

  &__dropdown {
    position: absolute;
    top: 10px;
    right: 0;
  }

  &__name {
    color: $accent-color;
    padding-right: 40px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
  }

  &__email, &__position {
    margin-top: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: rgba(22, 11, 36, 0.7);
    letter-spacing: 0.02em;
  }
}
