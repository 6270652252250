.category-list {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 20px;
  transition: 1s all;
  overflow: auto;
  padding-bottom: 10px;

  @include for-phone-only {
    margin-bottom: 40px;
    padding: 0 20px;
  }

  &_show {
    opacity: 1;
    pointer-events: unset;
  }

  &_hide {
    opacity: 0;
    pointer-events: none;
  }

  &__on-top {
    position: fixed;
    width: 100%;
    z-index: 5;
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(5px);
    border-bottom: 1px solid #dddddd;
    padding: 17px;
    text-align: center;
    transition: 0.3s all;

    &-fromStart {
      justify-content: start;
    }

    @include for-phone-only {
      justify-content: start;
    }
  }

  &__item {
    margin-right: 15px;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: #8b8592;
    display: flex;
    align-items: center;
    white-space: nowrap;

    &:hover {
      cursor: pointer;
    }

    &::after {
      content: '';
      display: block;
      height: 15px;
      width: 2px;
      background: #8b8592;
      margin-left: 17px;
      cursor: unset;
    }

    &:last-child {
      padding-right: 20px;

      &::after {
        display: none;
      }
    }

    &-active {
      color: #160b24;
    }
  }
}
