.user-burgermenu-modal {

  &__link {
    text-align: center;

    &-bottom {
      margin-top: 50px;
    }

    &-top {
      margin-top: -20px;
      margin-bottom: 50px;
    }
  }

  &__body {
    width: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding: 0 20px;
  }

  &__menu {
    width: 100%;
  }
}