.sign-up {
  &__form {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 20px 0;


    &-already-registered {
      @include fxc;
      flex-direction: column;
      font-size: 24px;
      line-height: 32px;
      @include for-phone-only {
        font-size: 18px;
        line-height: 22px;
      }

      p {
        color: $font-color-l;
        text-align: center;
      }

      a {
        margin-top: 10px;
        color: $font-color-d;
        text-decoration: none;
        font-weight: 600;
      }
    }
  }

  &__button {
    margin-top: 100px;
    @include for-phone-only {
      margin-top: auto;
    }
  }
}
