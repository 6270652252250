.input {
  width: 100%;
  margin-bottom: 25px;
  position: relative;

  &__error {
    .input__wrapper {
      border-bottom: 1px solid #ff006b;
    }
  }

  &__wrapper {
    @include fxc;
    justify-content: space-between;
    min-height: 44px;
    width: 100%;
    background-color: $bcg-color;
    color: $font-color-d;
    border-bottom: 1px solid #DDDDDD;
  }

  &__unit-width {
    opacity: 0;
    position: absolute;
    left: -100%;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    @include for-phone-only {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__unit-text {
    color: rgba(22, 11, 36, 0.5);
    font-size: 14px;
    padding: 10px;
    position: absolute;
    left: 20px;
    transition: 0.2s ease all;
    pointer-events: none;
    @include for-phone-only {
    }
  }

  &__field {
    display: flex;
    width: 100%;
    flex-direction: column-reverse;
  }

  &__component {
    width: 100%;
    margin: 10px 0;
    padding: 0;
    -webkit-appearance: none;
    border: none;
    font-size: 16px;
    font-weight: 500;

    &_moved-right {
      padding-left: 10px;
    }

    &-text-area {
      overflow: hidden !important;
      resize: none;
    }

    &:focus {
      outline: none;
    }

    //&:invalid {
    //  color: $font-color-d;
    //}
  }

  &__label {
    color: $font-color-l;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.02em;
    padding: 14px 0;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.2s ease all;
    pointer-events: none;
    transform-origin: left top;
    transform: scale(1);

    @include for-phone-only {
      font-size: 14px;
    }
    &_moved-right {
      left: 35px;
    }
  }

  &__component,
  &__label {
    transition: all 0.2s;
    touch-action: manipulation;
  }

  &__component:not(:placeholder-shown) + &__label,
  &__component:focus + &__label {
    top: -15px;
    transform: scale(0.8); /* [1] */
    cursor: pointer; /* [2] */
  }

  &__icons {
    cursor: pointer;
    @include fxc;
    position: relative;
    right: 0;
    margin-left: 10px;
  }

  &__icon {
    margin-left: 10px;
  }

  &__required-text {
    font-size: 0.6em;
    color: rgba(22, 11, 36, 0.5);
    white-space: pre;
  }

  &__error-message {
    margin-top: 5px;
    padding-left: 8px;
    width: 100%;
    color: #ff006b;
    font-size: 0.6em;
  }

  /**
* By default, the placeholder should be transparent. Also, it should
* inherit the transition.
*/
  ::-webkit-input-placeholder {
    transition: inherit;
    opacity: 0;
  }
  /**
  * Show the placeholder when the input is focused.
  */
  input:focus::-webkit-input-placeholder {
    opacity: 0;
  }
}
