@mixin fxc {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin transition($property) {
  transition: $property 0.3s linear;
}

@mixin scrollbarMixin {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: $gradient;
  }
  &::-webkit-scrollbar-thumb {
    background: $gradient;
    border: 2px solid rgba(0, 0, 0, 0.08);
    border-radius: 15px;
  }
}

@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin for-phone-up {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}

@mixin for-tablet-landscape-down {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin for-desktop-down {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin for-big-desktop-down {
  @media (max-width: 1800px) {
    @content;
  }
}

@mixin link {
  &,
  &:hover,
  &:active,
  &:visited,
  &:focus {
    @content;
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-ms-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
}

@mixin placeholder-a-left {
  @include placeholder {
    @content;
    opacity: 1;
    text-indent: 0px;
    transition: 0.3s all;
  }

  &:focus {
    @include placeholder {
      opacity: 0;
      text-indent: -50px;
    }
  }
}

@mixin placeholder-a-fade {
  @include placeholder {
    @content;
    opacity: 1;
    transition: 0.3s all;
  }

  &:focus {
    @include placeholder {
      opacity: 0;
    }
  }
}

@mixin no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

@mixin select {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
}

@mixin full {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@mixin full-abs {
  position: absolute;
  @include full;
}

@mixin full-fix {
  position: fixed;
  @include full;
}

@mixin full-rel {
  position: relative;
  width: 100%;
  height: 100%;
}

@mixin ios-border-radius-null {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
}

@mixin text-reduction {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0;
}

@mixin filled-icon {
  svg > path {
    fill: $bcg-color;
  }

  svg > circle {
    @include transition(all);
    stroke: $link-color;
    fill: $link-color;
  }

  &:hover {
    svg > path {
      fill: $bcg-color;
    }

    svg > circle {
      fill: $hover-color;
      stroke: $hover-color;
    }
  }
}
