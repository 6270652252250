/**
  COLORS
 */
$m-headerHeight: 140px;
$headerHeight: 150px;

$container-width: 670px;

$gradient: linear-gradient(57.64deg, #20ca68 7.07%, #9aadf1 91.95%);
$link-color: #20ca68;
$hover-color: #27ae60;
$accent-color: #160b24;
$bcg-color: #ffffff;
$bcg-color-d: #fafafa;

$disable-color: #8B8592;

$font-color-d: #160b24;
$font-color-l: #8b8592;

$border-color: #dddddd;

$white: #ffffff;

/**
    TEXT
 */

@mixin h1 {
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
  letter-spacing: 0.01em;
}

@mixin h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.01em;
}

@mixin h3 {
  font-weight: 600;
  font-size: 17px;
  line-height: 30px;
  letter-spacing: 0.02em;
}

@mixin p1 {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
}
@mixin p2 {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
}
